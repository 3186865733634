<template>
  <div class="content">
    <app-cells position="start">
      <router-link
        :to="{ name: 'event-detail', params: { id: $route.params.id } }"
        class="btn btn--link"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Добавление направления</h1>
    </app-cells>
    <form @submit.prevent="onCheckForm">
      <app-form-group label-for="dir-name" label="Название направления" required>
        <app-input
          v-model.trim="form.name"
          id="name"
          placeholder="Введите название направления"
          :class="{ 'input--error': $v.form.name.$error }"
          @change.native="$v.form.name.$touch()"
        />
        <template #error>
          <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
        </template>
      </app-form-group>
      <app-grid indent>
        <template #item-1>
          <app-form-group label-for="date_start_app" label="Дата и время начала приема заявок" required>
            <app-input
              v-model="form.date_start_app"
              v-mask="'99.99.9999 99:99'"
              id="date_start_app"
              placeholder="дд.мм.гггг чч:мм"
              :class="{ 'input--error': $v.form.date_start_app.$error }"
              autocomplete="off"
              @change.native="$v.form.date_start_app.$touch()"
            />
            <template #error>
              <div v-if="$v.form.date_start_app.$dirty && !$v.form.date_start_app.required">Обязательное поле</div>
              <div v-if="$v.form.date_start_app.$dirty && !$v.form.date_start_app.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
          <app-form-group label-for="date_end_app" label="Дата и время окончания приема заявок" required>
            <app-input
              v-model="form.date_end_app"
              v-mask="'99.99.9999 99:99'"
              id="date_end_app"
              placeholder="дд.мм.гггг чч:мм"
              :class="{ 'input--error': $v.form.date_end_app.$error }"
              autocomplete="off"
              @change.native="$v.form.date_end_app.$touch()"
            />
            <template #error>
              <div v-if="$v.form.date_end_app.$dirty && !$v.form.date_end_app.required">Обязательное поле</div>
              <div v-if="$v.form.date_end_app.$dirty && !$v.form.date_end_app.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label-for="date_start" label="Дата и время начала направления" required>
            <app-input
              v-model="form.date_start"
              v-mask="'99.99.9999 99:99'"
              id="date_start"
              placeholder="дд.мм.гггг чч:мм"
              :class="{ 'input--error': $v.form.date_start.$error }"
              autocomplete="off"
              @change.native="$v.form.date_start.$touch()"
            />
            <template #error>
              <div v-if="$v.form.date_start.$dirty && !$v.form.date_start.required">Обязательное поле</div>
              <div v-if="$v.form.date_start.$dirty && !$v.form.date_start.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
          <app-form-group label-for="date_end" label="Дата и время окончания направления" required>
            <app-input
              v-model="form.date_end"
              v-mask="'99.99.9999 99:99'"
              id="date_end"
              placeholder="дд.мм.гггг чч:мм"
              :class="{ 'input--error': $v.form.date_end.$error }"
              autocomplete="off"
              @change.native="$v.form.date_end.$touch()"
            />
            <template #error>
              <div v-if="$v.form.date_end.$dirty && !$v.form.date_end.required">Обязательное поле</div>
              <div v-if="$v.form.date_end.$dirty && !$v.form.date_end.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-form-group label="Необходимая информация из профиля">
        <app-cells position="start">
          <app-checkbox-list v-model="form.user_data_fields" :data="fields" />
        </app-cells>
      </app-form-group>
      <app-cells position="between">
        <h2 class="title">Конструктор вопросов:</h2>
<!--        <app-form-group>-->
<!--          <v-select-->
<!--            @input="onBuilderTemplateChange"-->
<!--            v-model="current_template"-->
<!--            :options="templates"-->
<!--            :searchable="false"-->
<!--            :clearable="true"-->
<!--            label="name"-->
<!--            placeholder="... или выберите один из шаблонов"-->
<!--            class="select select&#45;&#45;minw"-->
<!--          >-->
<!--            <template slot="open-indicator">-->
<!--              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">-->
<!--                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>-->
<!--                <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>-->
<!--              </svg>-->
<!--            </template>-->
<!--            <template slot="option" slot-scope="option">-->
<!--              <div class="select__item">{{ option.name }}</div>-->
<!--            </template>-->
<!--            <template slot="selected-option" slot-scope="option">-->
<!--              <div class="d-center">{{ option.name }}</div>-->
<!--            </template>-->
<!--          </v-select>-->
<!--        </app-form-group>-->
      </app-cells>
      <div class="builder-list">
        <div class="builder-list__types">
          <button
            type="button"
            v-for="item in fields_types"
            :key="item.id"
            @click="onStartBuild(item)"
            class="builder-list__type"
          >
            <img :src="require('@/assets/img/builder/' + item.icon)" alt="">
            <div class="builder-list__text">{{ item.name }}</div>
          </button>
        </div>
        <div class="builder-list__field">
          <draggable
            v-bind="dragOptions"
            v-model="builder_form"
            group="types"
            class="builder-list__block"
          >
            <transition-group>
              <div
                class="builder-list__item"
                v-for="(element, index) in builder_form"
                :key="element.id"
              >
                <div class="builder-list__body">
                  <div class="builder-list__info">
                    <img :src="require('@/assets/img/builder/' + element.type + '.svg')" alt="">
                    <div v-if="element.label" class="builder-list__label">{{ element.label }}</div>
                    <div class="builder-list__name">{{ element.name }}</div>
                    <div v-if="element.required" class="builder-list__name">Обязательное поле</div>
                  </div>
                  <ul v-if="element.options.length" class="builder-list__options">
                    <li v-for="option in element.options" :key="option">{{ option }}</li>
                  </ul>
                </div>
                <div class="builder-list__controls">
                  <button type="button" @click="onBItemEdit(element)">
                    <img src="@/assets/img/builder/bEdit.svg" alt="">
                  </button>
                  <button type="button" @click="onBItemDelete(index, builder_form)">
                    <img src="@/assets/img/builder/bDelete.svg" alt="">
                  </button>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
      <AppCells>
        <AppButton :disabled="$v.form.$error || !builder_form.length" ref="submit">Добавить направление</AppButton>
      </AppCells>
    </form>
    <modal
      name="builder-popup"
      :width="700"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="builder-popup">
        <div class="builder-popup__container">
          <div class="builder-popup__title">
            {{ is_edit_popup ? 'Редактирование' : 'Добавление' }} - {{ popup_data.name }}
          </div>
          <app-form-group v-if="popup_data.type !== 'bParagraph' && popup_data.type !== 'bTitle'" label-for="builder_label" label="Вопрос" required>
            <app-input
              v-model.trim="popup_data.label"
              id="builder_label_match"
              placeholder="Введите текст вопроса"
              :class="{ 'input--error': $v.popup_data.label.$error }"
              @change.native="$v.popup_data.label.$touch()"
            />
            <template #error>
              <div v-if="$v.popup_data.label.$dirty && !$v.popup_data.label.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="popup_data.type === 'bTitle'" required>
            <app-input
              v-model.trim="popup_data.label"
              id="builder_label"
              placeholder="Введите текст заголовка"
              :class="{ 'input--error': $v.popup_data.label.$error }"
              @change.native="$v.popup_data.label.$touch()"
            />
            <template #error>
              <div v-if="$v.popup_data.label.$dirty && !$v.popup_data.label.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="popup_data.type === 'bParagraph'">
            <vue-editor
              v-model="popup_data.value"
              ref="qEditor"
              :editor-toolbar="customToolbar"
              class="editor"
              placeholder="Введите текст"
              @paste.native="onEditorPast"
            />
          </app-form-group>
          <app-form-group
            v-if="
                popup_data.type !== 'bRadio' &&
                popup_data.type !== 'bCheckbox' &&
                popup_data.type !== 'bDateTime' &&
                popup_data.type !== 'bDate' &&
                popup_data.type !== 'bUploader' &&
                popup_data.type !== 'bTitle' &&
                popup_data.type !== 'bParagraph' &&
                popup_data.type !== 'bPhone'
              "
            label-for="builder_placeholder"
            label="Подсказка в поле ввода"
          >
            <app-input
              v-model.trim="popup_data.placeholder"
              id="builder_placeholder"
              placeholder="Введите текст подсказки для поля ввода"
            />
          </app-form-group>
          <app-form-group
            v-if="popup_data.type !== 'bTitle' && popup_data.type !== 'bParagraph'"
            label-for="builder_tooltip"
            label="Всплывающая подсказка"
          >
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <img src="@/assets/img/tooltip-icon.svg" alt="tooltip">
                </template>
                <template #content>
                  <p>Пример всплывающей подсказки</p>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model.trim="popup_data.tooltip"
              id="builder_tooltip"
              placeholder="Введите текст всплывающей подсказки"
            />
          </app-form-group>
          <app-form-group v-if="popup_data.type === 'bUploader'" label="Допустимые форматы файлов">
            <app-cells position="start">
              <app-checkbox-list v-model="popup_data.extensions" :data="extensions" />
            </app-cells>
          </app-form-group>
          <app-form-group v-if="popup_data.type !== 'bTitle' && popup_data.type !== 'bParagraph'">
            <AppCheckbox v-model="popup_data.required" label="Обязательное поле" />
          </app-form-group>
          <!--select, radio, checkbox-->
          <app-form-group
            v-if="popup_data.type === 'bSelect' || popup_data.type === 'bRadio' || popup_data.type === 'bCheckbox'"
            label="Ответы"
          >
            <app-form-group
              v-for="(item, index) in popup_data.options"
              :key="index"
              :label-for="`option_${index}`"
              required
            >
              <template #right_place>
                <app-button
                  v-if="popup_data.type === 'bRadio' || popup_data.type === 'bSelect' ?  index > 1 : index > 0"
                  @click="onDeleteItem(index)"
                  size="link"
                  type="button"
                >
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </app-button>
              </template>
              <app-input
                v-model.trim="popup_data.options[index]"
                :id="`option_${index}`"
                placeholder="Введите вариант ответа"
                :class="{ 'input--error': $v.popup_data.options.$each[index].$error }"
                @change.native="$v.popup_data.options.$each[index].$touch()"
              />
              <template #error>
                <div v-if="$v.popup_data.options.$each[index].$dirty && !$v.popup_data.options.$each[index].required">
                  Обязательное поле
                </div>
              </template>
            </app-form-group>
            <app-button @click="onCloneItem" size="link" type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить вариант</span>
            </app-button>
            <template #error>
              <div v-if="$v.popup_data.options.$dirty && !$v.popup_data.options.required">Обязательное поле</div>
              <div v-if="$v.popup_data.options.$dirty && !$v.popup_data.options.minLength">
                Количество опций должно быть минимум 2
              </div>
            </template>
          </app-form-group>
        </div>
        <app-cells>
          <app-button v-if="!is_edit_popup" @click="onPushPopupData" type="button">Добавить</app-button>
          <app-button v-else @click="closePopup" type="button">Сохранить</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

import { VueEditor } from 'vue2-editor'
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'
import draggable from 'vuedraggable'

export default {
  name: 'DirectionCreate',
  components: {
    draggable,
    VueEditor
  },
  validations() {
    return {
      form: {
        name: { required },
        date_start_app: { required, underscorePresent },
        date_end_app: { required, underscorePresent },
        date_start: { required, underscorePresent },
        date_end: { required, underscorePresent },
      },
      popup_data: {
        label: {
          required: requiredIf(function () {
            return this.popup_data.type !== 'bParagraph'
          }),
        },
        options: {
          required: requiredIf(function () {
            return this.popup_data.type === 'bSelect' || this.popup_data.type === 'bRadio' || this.popup_data.type === 'bCheckbox'
          }),
          minLength: this.popup_data.type !== 'bCheckbox' ? minLength(2) : minLength(false),
          $each: {
            required: requiredIf(function () {
              return this.popup_data.type === 'bSelect' || this.popup_data.type === 'bRadio' || this.popup_data.type === 'bCheckbox'
            }),
          }
        },
      }
    }
  },
  data() {
    return {
      form: {
        user_data_fields: []
      },
      builder_form: [],
      fields_types: [
        { type: 'bInput', name: 'Короткий текст', icon: 'bInput.svg' },
        { type: 'bTextarea', name: 'Длинный текст', icon: 'bTextarea.svg' },
        { type: 'bNumber', name: 'Число', icon: 'bNumber.svg' },
        { type: 'bRadio', name: 'Один вариант', icon: 'bRadio.svg' },
        { type: 'bCheckbox', name: 'Несколько вариантов', icon: 'bCheckbox.svg' },
        { type: 'bSelect', name: 'Выпадающий список', icon: 'bSelect.svg' },
        { type: 'bDate', name: 'Дата', icon: 'bDate.svg' },
        { type: 'bDateTime', name: 'Дата и время', icon: 'bDateTime.svg' },
        { type: 'bUploader', name: 'Файл', icon: 'bUploader.svg' },
        { type: 'bTitle', name: 'Заголовок', icon: 'bTitle.svg' },
        { type: 'bParagraph', name: 'Абзац текста', icon: 'bParagraph.svg' },
        { type: 'bPhone', name: 'Телефон', icon: 'bPhone.svg' },
        { type: 'bEmail', name: 'Электронная почта', icon: 'bEmail.svg' },
        { type: 'bLink', name: 'Ссылка', icon: 'bLink.svg' },
        { type: 'bAddress', name: 'Адрес', icon: 'bAddress.svg' },
        { type: 'bOrg', name: 'Организация', icon: 'bOrg.svg' },
      ],
      extensions: [
        { name: 'изображения', id: 'image/jpg, image/jpeg, image/png'},
        { name: 'pdf', id: 'application/pdf' },
        { name: 'excel', id: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' },
      ],
      popup_data: {
        type: '',
        tooltip: '',
        value: null,
        required: false,
        label: '',
        placeholder: '',
        options: [],
        system_value: {}
      },
      is_edit_popup: false,
      fields: [],
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'], ['clean']
      ],
      templates: [],
      current_template: null
    }
  },
  mounted() {
    this.popup_data = { type: '', id: 0, tooltip: '', value: null, required: false, label: '', placeholder: '', options: [] }
    this.$store.dispatch('dict/GET_EVENT_FIELDS').then(response => { this.fields = response.data })
    this.$store.dispatch('dict/GET_BUILDER_TEMPLATES').then(response => { this.templates = response.data })
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost'
      }
    },
  },
  methods: {
    onBuilderTemplateChange() {
      if (this.current_template) this.builder_form = this.current_template.form
      else this.builder_form = []
    },
    onEditorPast() {
      this.$refs.qEditor.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops = delta.ops.map(op => {
          return { insert: op.insert }
        })
        return delta
      })
    },
    onStartBuild(item) {
      this.popup_data = { type: '', name: '', tooltip: '', id: 0, value: null, required: false, label: '', placeholder: '', options: [] }
      this.popup_data.type = item.type
      this.popup_data.name = item.name
      if (item.type === 'bUploader') this.popup_data.extensions = []
      if (item.type === 'bCheckbox') this.popup_data.options.push('')
      if (item.type === 'bRadio') this.popup_data.options.push('', '')
      if (item.type === 'bSelect') this.popup_data.options.push('', '')
      this.is_edit_popup = false
      this.$v.$reset()
      this.$modal.show('builder-popup')
    },
    onPushPopupData() {
      this.$v.popup_data.$touch()
      if (this.$v.popup_data.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.popup_data.id = `${this.popup_data.type}_${Math.round(new Date().getTime() / 1000)}`
        this.builder_form.push(this.popup_data)
        this.closePopup()
      }
    },
    onDeleteItem(index) {
      this.popup_data.options.splice(index, 1)
    },
    onCloneItem() {
      this.popup_data.options.push('')
    },
    onBItemDelete(index, list) {
      list.splice(index, 1)
    },
    onBItemEdit(item) {
      this.popup_data = item
      this.is_edit_popup = true
      this.$modal.show('builder-popup')
    },
    closePopup() {
      this.$modal.hide('builder-popup')
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.form.form = this.builder_form
      this.$store.dispatch('events/directions/POST_DATA', { data: this.form, id: this.$route.params.id })
        .then(() => {
          this.$refs.submit.preload = false
          this.$router.push({ name: 'events-manage' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Направление успешно создано.'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  }
}
</script>
